<template>
  <b-nav-item @click="getTargetRoute">
    <feather-icon
      size="21"
      icon="CastIcon"
    />
  </b-nav-item>
</template>

<script>
import { BNavItem } from 'bootstrap-vue'

export default {
  components: {
    BNavItem,
  },
  setup() {
  },
  methods: {
    getTargetRoute() {
      let routeData = this.$router.resolve({ path: '/jhdp' });
      window.open(routeData.href, '_blank');
    }
  }
}
</script>
